<template>
  <base-modal class="modal-agent-not-found" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header>
      <h3>ALTERAR SENHA DO {{nameReplace || "USUÁRIO"}}</h3>
    </template>
    <template v-slot:content>
      <base-input-text
        v-model="password"
        type="text"
        label="Senha"
        placeholder="*******************"
        block
      />
      <base-input-text
        v-model="confirmPassword"
        type="text"
        label="Confirme a senha"
        placeholder="*******************"
        block
      />
    </template>
    <template v-slot:footer>
      <base-button
        @click="closeModal"
        block
        dark
      >
        Cancelar
      </base-button>
      <base-button
        :disabled="!canSubmit()"
        @click="submit"
        block
        sucess
      >
        Salvar
      </base-button>
    </template>
  </base-modal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ModalAgentNotFound',
  props: {
    isOpen: { type: Boolean, default: false },
    _id: { type: String, default: '' },
    name: { type: String, default: '' },
  },
  data(){
    return {
      password: '',
      confirmPassword: '',
      messages: {
        '_id is not an ObjectId': 'Erro ao alterar senha',
        'Passwords do not match': 'Senhas não são iguais',
        'Error changing password': 'Erro ao alterar senha',
        'Password changed successfully': 'Senha alterada com sucesso'
      }
    }
  },
  computed: {
    nameReplace() {
      return this.name.split(' ')[0].toUpperCase()
    },
  },
  methods: {
    ...mapActions('user', {
      handleChangePassword: 'handleChangePassword'
    }),
    closeModal() {
      this.password = ''
      this.confirmPassword = ''
      
      this.$emit('close')
    },
    canSubmit() {
      if(!this.password || !this.confirmPassword) return false
      if(this.password !== this.confirmPassword) return false
      return true
    },
    async submit() {
      let message = ''
      let variant = 'success'

      if(!this.canSubmit()) return 

      const response = await this.handleChangePassword({
        _id: this._id,
        password: this.password,
        confirmPassword: this.confirmPassword,
      })

      if(response.errors) {
        message = this.messages[response.errors];
        variant = 'danger';
      } else if(response.data.message) {
        message = this.messages[response.data.message];
      }

      this.$bvToast.toast(message, {
        title: 'HOFácil WEB',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      })

      this.closeModal()
    },
  }
}
</script>

<style lang="scss" scoped>

.modal-agent-not-found {
  /deep/ header {
    padding: 0.5rem;
    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }
}
</style>