<template>
  <div class="users-view mt-3">
    <modal-change-password
      @close="closeModal"
      :is-open="modalChangePassword.open"
      :name="modalChangePassword.user.name"
      :_id="modalChangePassword.user._id"
    />

    <user-table
      @change-password="openModal"
    />
  </div>
</template>

<script>
import UserTable from '@/components/Users/Table'
import ModalChangePassword from '@/components/Users/ModalChangePassword'
import { mapActions } from 'vuex'

export default {
  name: 'Users',
  data() {
    return {
      loading: false,
      modalChangePassword: {
        open: false,
        user: {
          _id: '',
          name: ''
        }
      },
      modalDeleteAccount: {
        open: false,
        user: {
          _id: '',
          username: '',
        }
      }
    }
  },
  components: {
    UserTable,
    ModalChangePassword,
  },
  created() {
    const breadcrumb = {
      page: 'Usuários',
      subPage: {
        name: 'Usuários',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  methods: {
    ...mapActions('user', {
      handleGetUsers: 'handleGetUsers',
      handleDeleteAccount: 'handleDeleteAccount',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    closeModal(){
      this.modalChangePassword = {
        open: false,
        user: {
          _id: '',
          name: ''
        }
      }
    },
    openModal(user){
      this.modalChangePassword = {
        open: true,
        user: {
          _id: user._id || '',
          name: user.name || ''
        }
      }
    },
  }
}
</script>

