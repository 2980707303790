<template>
  <div class="user-table">
    <b-overlay :show="loadingUserList" rounded="sm">
      <div class="search">
        <div class='w-50'>
          <b-form-group >
            <b-form-input
              id="searchUser"
              v-model="search"
              type="text"
              @keydown.enter.prevent='handleGetUsers({ search: search })'
              placeholder="Pesquisar por: E-mail, cpf ou telefone"
            ></b-form-input>
          </b-form-group>
        </div>
        <base-button
          class='mx-2 btn'
          :disabled="!search"
          @click="handleGetUsers({ search: search }), searchedUser = true"
        >
          Pesquisar
        </base-button>

        <base-button
          class='btn'
          @click="search = ''"
        >
          Limpar
        </base-button>
      </div>

      <base-table
      v-if="users && usersMeta"
      :columns="columns"
      :data="users"
      @sort-by="handleSortUsers"
      :total-items="usersMeta.total"
      :start-items="usersMeta.skip + 1"
      :max-items="usersMeta.limit"
      @set-page="changeUsers"
    >
      <template v-slot:body-name="row">
        <span v-if="row.item.profile && row.item.profile.name">
          {{ row.item.profile.name }}
        </span>
      </template>
      <template v-slot:body-phone="row">
        <span v-if="row.item.profile && row.item.profile.phone">
          {{ row.item.profile.phone }}
        </span>
      </template>
      <template v-slot:body-comercialPhone="row">
        <span v-if="row.item.profile && row.item.profile.comercialPhone">
          {{ row.item.profile.comercialPhone }}
        </span>
      </template>
      <template v-slot:body-lastAccess="row">
        <span v-if="row.item.lastAccess">
          {{ formatDate(row.item.lastAccess) }}
        </span>
      </template>
      <template v-slot:body-actions="row">
        <i
          v-if='!readOnly'
          class="fas fa-key fa-lg"
          @click="changePassword(row.item)"
        />
        <i
          class="fas fa-search-plus fa-lg"
          @click="seeMoreUser(row.item._id)"
        />
      </template>
    </base-table>
      <div v-if="!users || users.length <= 0">
        <p v-if='!searchedUser' class='text-center py-3'>
          Para pesquisar usuários, basta digitar o e-mail e clicar em pesquisar.
        </p>
        <p v-if='searchedUser && (!users || users.length <= 0)' class='text-center py-3'>
          Nenhum usuário encontrado.
        </p>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from "vuex"
import _ from 'underscore'

export default {
  name: 'UserTable',
  data(){
    return {
      searchedUser: false,
      dialog: {
        open: false,
        email: '',
        status: '',
        _id: '',
      },
      columns: [
        { key: 'name', value: 'Nome', sort: 'profile.name' },
        { key: 'username', value: 'Email', sort: true },
        { key: 'phone', value: 'Telefone', sort: 'profile.phone' },
        { key: 'comercialPhone', value: 'Telefone comercial', sort: 'profile.comercialPhone' },
        { key: 'lastAccess', value: 'Último acesso'},
        { key: 'actions', value: 'Ações'},
      ],
      search: '',
    }
  },
  computed: {
    ...mapGetters('user', {
      users: 'getUsers',
      usersMeta: 'getUsersMeta',
      loadingUserList: 'loadingUserList',
    }),
    currentUser() {
      return this.$store.getters['user/current']
    },
    readOnly(){
      return !!this.currentUser.functionalities.find((x) => x.name === 'Gerenciar usuário (somente leitura)')
    },
  },
  methods: {
    ...mapActions('user', {
      handleGetUsers: 'handleGetUsers',
    }),
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy')
    },
    seeMoreUser(_id){
      this.$router.push({ name: 'UserDetails', params: { _id }})
    },
    async handleSortUsers({ page, sortBy, sortIn }){
      await this.handleGetUsers({
        skip: (page - 1) * this.usersMeta.limit,
        sortBy,
        sortIn,
        search: this.search
      });
    },
    async changeUsers(page){
      await this.handleGetUsers({
        ...this.usersMeta,
        skip: (page - 1) * this.usersMeta.limit,
        sortBy: this.usersMeta.sortBy,
        sortIn: this.usersMeta.sortIn,
        search: this.search
      });
    },
    changePassword(user){
      let payload = {
        _id: '',
        name: ''
      }

      if(user._id){
        payload._id = user._id
      }

      if(user.profile && user.profile.name){
        payload.name = user.profile.name
      }

      this.$emit('change-password', payload)
    },
  },
  async mounted() {
  },
  async created(){
  },
  watch: {
  },
}
</script>

<style lang="scss" scoped>
.user-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .search {
    width: 100%;
    display: flex;
    justify-content:flex-end;

    /deep/ input {
      width: 100%;
      height: 50px!important;
    }
    .btn{
      height: 50px;
    }
  }
  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
